export enum EMainCategory {
  REAL_ESTATES = 'REAL_ESTATES', //1
  APARTMENTS = 'APARTMENTS', //2
  HOUSES = 'HOUSES', //3
  COTTAGES_AND_CABINS = 'COTTAGES_AND_CABINS', //4
  LANDS = 'LANDS', //5
  SPACES = 'SPACES', //6
  OBJECTS = 'OBJECTS', //7
  SECONDARY_HOUSES = 'SECONDARY_HOUSES', //200000
  SECONDARY_RECREATIONAL_PROPERTIES = 'SECONDARY_RECREATIONAL_PROPERTIES', //200001
  SECONDARY_LANDS = 'SECONDARY_LANDS', //200002
  SECONDARY_SPACES_AND_OBJECTS = 'SECONDARY_SPACES_AND_OBJECTS' //200003
}