'use client';

import {useRecoilState} from 'recoil';
import { usePathname } from 'next/navigation';
import { FC, useEffect, useMemo } from 'react';
import {appUserIsLoggedIn} from 'modules/state/app/state';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { listingAdvertisementIds } from 'modules/state/page/listing/state';
import { pageViewAdvertisementDetailState } from 'modules/state/gaAnalytics/pageView/state';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

const DEFAULT_GEMIUS_ID = '0nUwO29Lyzs368kggazNSsVlTO4Bgayu6KWvUX37cyL.W7';
const gemiusPathToId: Record<string, string> = {
    '/realitne-kancelarie-makleri': 'ciuQ.Y8Y2ypnacjukW_1IuUC7MPRNOxtiAK.Zc57g6..g7',
    '/realitne-kancelarie':
        'ciuQ.Y8Y2ypnacjukW_1IuUC7MPRNOxtiAK.Zc57g6..g7',
    '/realitna-kancelaria':
        'ciuQ.Y8Y2ypnacjukW_1IuUC7MPRNOxtiAK.Zc57g6..g7',
    '/realitni-makleri':
        'ciuQ.Y8Y2ypnacjukW_1IuUC7MPRNOxtiAK.Zc57g6..g7',
    '/realitny-makler':
        'ciuQ.Y8Y2ypnacjukW_1IuUC7MPRNOxtiAK.Zc57g6..g7',
    '/':
        'bJE639gQp9SS.h.TrMAp88Vl7DoBgexR6IWfDH6Lm33.R7',
    '/developerske-projekty-a-novostavby':
        'zCs6GLiTp0sNurBlEPOFSceyP_ikgbsR_igkH3EaeX7.d7'
};

const rtbHousePathToId: Record<string, string> = {
    '/': 'home',
    'detail': 'offer',
    '/vysledky': 'listing',
};

const getLivemonitorPathToEvent = (
    params: Record<string, string>
): Record<string, string[]> => {
    return {
        '/': ['HP'],
        '/realitne-kancelarie-makleri': ['adresar-agency'],
        '/realitne-kancelarie': ['adresar-agency'],
        '/realitna-kancelaria': ['detail-agency', params?.['branchId'] || ''],
        '/realitni-makleri': ['adresar-agency'],
        '/realitny-makler': ['detail-agency', params?.['agentId'] || ''],
        '/vysledky': ['Advert search'],
        '/detail': [
            params?.['gallery'] ? 'Advert-fotogallery' : 'Advert',
            params?.['id'] || '',
        ]
    };
};

interface IUrl {
    pathname: string;
    asPath: string;
    params: Record<string, string>;
}

const serializeUrl = (url: IUrl): string => {
    return JSON.stringify(url);
};

const unserializeUrl = (serializedUrl: string): IUrl => {
    return JSON.parse(serializedUrl);
};

interface IPageViewProps {
    searchParams: Record<string, string>
}

const PageView: FC<IPageViewProps> = ({ searchParams }) => {
    const {
        facebookPixel: { pageView: facebookPixelPageView },
        gemius: { pageView: gemiusPageView },
        rtbHouse: { sendEvent: rtbHouseSendEvent },
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    const pathname = usePathname();

    const [isAdvertiserLoggedIn] = useRecoilState(appUserIsLoggedIn);
    const [listingSearchResultadvertisementIds] = useRecoilState(listingAdvertisementIds);
    const [advDetailState] = useRecoilState(pageViewAdvertisementDetailState);

    const urlSerialized = useMemo(() => {
        const pathnameDefault = pathname || '';
        return serializeUrl({
            pathname: pathnameDefault,
            asPath: pathnameDefault,
            params: searchParams,
        });
    }, [pathname, searchParams]);

    useEffect(() => {
        facebookPixelPageView();
    }, [facebookPixelPageView, urlSerialized]);

    useEffect(() => {
        const { pathname, params } = unserializeUrl(urlSerialized);
        let gemiusId = gemiusPathToId[pathname] || DEFAULT_GEMIUS_ID;

        if (pathname.startsWith('/detail')) {
            if (!advDetailState?.mainCategoryValue && !params?.['gallery']) {
                return;
            }

            switch (advDetailState?.mainCategoryValue) {
            case EMainCategory.APARTMENTS:
                gemiusId = 'zDsw27fDy7tisCjsnKXtp5cP.h3UNgbnzSLoaeUccM..j7';
                break;
            case EMainCategory.COTTAGES_AND_CABINS:
                gemiusId = 'csuQ_4_Y2vLNU2j0mmglxJbx7KPUKuxdo3sli5C8m9P.G7';
                break;
            case EMainCategory.LANDS:
                gemiusId = 'css6QY_Yp4tnn2awEQHN0pbxTIPUKqyizSvPGOUsA2..h7';
                break;
            case EMainCategory.HOUSES:
                gemiusId = 'cjU6P4_lp1QHirAHATSBLuTkXtDRKMdD8pL4riruSGT.f7';
                break;
            case EMainCategory.OBJECTS:
            case EMainCategory.SPACES:
                gemiusId = 'zDuan7fDt4wMhmZwnCY1y5cPTO3UNqyuA9Kv0_Avc87.c7';
                break;
            }

            if (params?.['gallery']) {
                gemiusId = '.X1FL6tJ4W9_As2NXbUEK6RtXovGpce112XrB1YdOMH.M7';
            }
        }

        if (pathname.startsWith('/vysledky')) {
            if (params?.['first']) {
                switch (params['first']) {
                case 'byty':
                    gemiusId = 'css64Y_YpwsHlGXQARdFGpbx.h3UKgbnA6VC44f3WEP.Z7';
                    break;
                case 'pozemky':
                    gemiusId = 'csua.4_YtwvN7VA7GsbhGOUC.j3RNAb3iAJii86j8Bz.C7';
                    break;
                case 'domy':
                    gemiusId = 'zDswPbfDy3IC2o9rfNWQyZevXtDUdsdDAwwYtYgPWOH.m7';
                    break;
                case 'priestory-a-objekty':
                    gemiusId = 'ciuQnY8Y26oHxC_RuaZw05dRTO3U6qyuzZw1EY1my8..n7';
                    break;
                case 'rekreacne-nehnutelnosti':
                    gemiusId = 'csuaAY_Yt0Nt52ZQwqG9guUC7KPRNOxd8hAFQ4Org1f..7';
                    break;
                default:
                    gemiusId = 'csuaAY_Yt0LNjWZQ0tY9guWiXkbRdMcnUgQYF.rwsDT.R7';
                }
            } else {
                gemiusId = 'csuaAY_Yt0LNjWZQ0tY9guWiXkbRdMcnUgQYF.rwsDT.R7';
            }
        }
        gemiusPageView({
            id: gemiusId,
        });
    }, [gemiusPageView, advDetailState?.mainCategoryValue, urlSerialized]);

    useEffect(() => {
        if (isAdvertiserLoggedIn === undefined) {
            return;
        }
        const { pathname, params } = unserializeUrl(urlSerialized);
        const eventType = rtbHousePathToId[pathname] || 'unknown';
        const pageViewEvent = {
            eventType: eventType,
        };

        if (pathname.startsWith('/detail') && params?.['id']) {
            pageViewEvent.eventType = rtbHousePathToId['detail'];
            pageViewEvent['offerId'] = params['id'];
        }

        if (pathname.startsWith('/vysledky')) {
            if (listingSearchResultadvertisementIds === null) {
                return;
            }

            pageViewEvent.eventType = rtbHousePathToId['/vysledky'];
            pageViewEvent['offerIds'] = listingSearchResultadvertisementIds;
        }

        rtbHouseSendEvent( pageViewEvent );
    }, [isAdvertiserLoggedIn, listingSearchResultadvertisementIds, rtbHouseSendEvent, urlSerialized]);

    useEffect(() => {
        const { pathname, params } = unserializeUrl(urlSerialized);
        let eventData: string[] = [];
        Object.entries(getLivemonitorPathToEvent(params)).forEach((path => {
            if (pathname.startsWith(path[0])) {
                eventData = path[1];
            }
        }));

        if (!eventData.length) {
            return;
        }

        livemonitorSendEvent({
            p: ['pageView', ...eventData],
            o: { pn: 'nehnutelnosti'}
        });
        livemonitorSendEvent({
            p: ['pageView', ...eventData],
            o: { pn: 'plt-stats'}
        });


    }, [livemonitorSendEvent, urlSerialized]);

    return null;
};

export default PageView;
