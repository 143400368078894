'use client';

import {useRecoilState} from 'recoil';
import { usePathname } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { getCookie } from 'modules/cookie/getCookie';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { clientTokenName } from 'modules/session/constants/clientTokenName';
import { pageViewAdvertisementDetailState } from 'modules/state/gaAnalytics/pageView/state';
import { EAdvertiserType } from 'modules/api/clients/advertisement/detail/enums/advertiser/EAdvertiserType';

const gtmPathToContentType: Record<string, string> = {
    '/': 'home',
    '/moje-konto': 'account',
    '/prihlasenie': 'account',
    '/nase-sluzby/doplnkove-sluzby': 'informational',
    '/nase-sluzby/inzercia': 'informational',
    '/nase-sluzby/bannery': 'informational',
    '/nase-sluzby/kontaktujte-nas': 'informational',
    '/nase-sluzby/portfolio': 'informational',
    '/nase-sluzby/pr-ponuka': 'informational',
    '/pomozeme-vam-s-predajom': 'ancillary service',
    '/rekreacne-domy/podla-regionov': 'ancillary service',
    '/developerske-projekty-a-novostavby': 'ancillary service',
    '/realitne-kancelarie-makleri': 'directory search results',
    '/realitne-kancelarie': 'directory search results',
    '/realitna-kancelaria': 'directory listing',
    '/realitni-makleri': 'directory search results',
    '/realitny-makler': 'directory listing',
    '/detail': 'classified listing',
    '/o-nas': 'informational',
    '/vysledky': 'classified search results',
    '/registracia-administracny-system': 'account',
    '/registracia-administracny-system-informacie': 'account',
    '/registracia-administracny-system-dakujeme': 'account',
    '/sluzby': 'informational',
    '/gdpr': 'informational',
    '/caroffice': 'informational',
};

interface IUrl {
    pathname: string;
    asPath: string;
    params: Record<string, string>;
}

const serializeUrl = (url: IUrl): string => {
    return JSON.stringify(url);
};

const unserializeUrl = (serializedUrl: string): IUrl => {
    return JSON.parse(serializedUrl);
};

export const usePageViewEvent = (searchParams: Record<string, string>): void => {
    const pathname = usePathname();
    const [isPushStateSended, setIsPushStateSended] = useState(false);
    const [advDetailState] = useRecoilState(pageViewAdvertisementDetailState);

    const {
        gtm: { sendEvent: gtmSendEvent }
    } = useAnalytics();

    const urlSerialized = useMemo(() => {
        const pathnameDefault = pathname || '';
        return serializeUrl({
            pathname: pathnameDefault,
            asPath: pathnameDefault,
            params: searchParams,
        });
    }, [pathname, searchParams]);

    useEffect(() => {
        const { asPath } = unserializeUrl(urlSerialized);

        gtmSendEvent({
            event: 'gtm.historyChange-v2',
            'gtm.historyChangeSource': 'pushState',
            'gtm.newUrl': `${AppConfigService.getNehnutelnostiUrl()}${asPath}`,
        });

        setIsPushStateSended(true);
    }, [urlSerialized, gtmSendEvent]);

    useEffect(() => {
        const isAdvertiserLoggedIn = !!getCookie(clientTokenName);

        let contentType = gtmPathToContentType[pathname];
        Object.entries(gtmPathToContentType).forEach((path => {
            if (pathname.startsWith(path[0])) {
                contentType = path[1];
            }
        }));

        if (contentType || pathname.startsWith('/detail')) {
            if (typeof window['dataLayer'] === 'undefined') {
                window['dataLayer'] = [];
            }

            const eventData = {
                event: 'page_view_spa',
                content_type: contentType,
                page_brand: AppConfigService.getTitle(),
                page_country_code: 'SK',
                page_language: 'sk',
                is_loggedin: isAdvertiserLoggedIn ? 'true' : 'undefined',
            };

            if (pathname.startsWith('/detail')) {
                if (!advDetailState?.advertiserType) {
                    return;
                }

                eventData.content_type = 'classified listing';

                let pageItemType = 'classified agency listing';

                if (searchParams?.['gallery']) {
                    pageItemType = 'classified agency gallery listing';
                    if (advDetailState?.advertiserType === EAdvertiserType.PRIVATE_PERSON) {
                        pageItemType = 'classified private gallery listing';
                    }
                } else {
                    if (advDetailState?.advertiserType === EAdvertiserType.PRIVATE_PERSON) {
                        pageItemType = 'classified private listing';
                    }
                }

                eventData['page_item_type'] = pageItemType;
            }

            if (isPushStateSended) {
                gtmSendEvent(eventData);
            }
        }
    }, [advDetailState?.advertiserType, urlSerialized, pathname, searchParams, isPushStateSended, gtmSendEvent]);
};