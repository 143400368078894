import { atom } from 'recoil';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { EAdvertiserType } from 'modules/api/clients/advertisement/detail/enums/advertiser/EAdvertiserType';

interface IPageViewAdvertisementDetailState {
    mainCategoryValue: EMainCategory;
    advertiserType: EAdvertiserType;
}

export const pageViewAdvertisementDetailState = atom<IPageViewAdvertisementDetailState | null>({
    key: 'page-view-adv-detail-state',
    default: null,
});

